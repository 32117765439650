<template>
  <v-card color="grey lighten-4" v-if="pool" :key="pool.id" class="pt-1" tile>
    <v-toolbar color="color2 color2Text--text" v-if="tournament.isLeague && $route.name === 'league-play'">
      <v-toolbar-title>{{flight.getPoolTitle(pool.number, tournament.leagueSettings)}}</v-toolbar-title>
    </v-toolbar>
    <v-container fluid class="pt-0" :class="{'px-2': $vuetify.breakpoint.xsOnly}">
      <v-alert
        type="warning"
        prominent
        :value="view.adminAndRefs && pool.complete && !pool.locked"
        transition="scale-transition"
      >
        <v-row align="center">
          <v-col class="grow">
            <strong class="black--text">{{division.name}} pool {{pool.name}} looks ready to lock.</strong>
          </v-col>
          <v-col class="shrink">
            <v-btn
              @click.stop="onLockToggle"
              :loading="loading"
              class="ml-3"
            >lock</v-btn>
          </v-col>
        </v-row>
      </v-alert>
      <v-alert type="error" :value="true" class="black--text ma-0" prominent text v-if="pool.teams.length === 0">
        <strong>We are still waiting on some results before this pool can be filled</strong>
      </v-alert>
      <v-row dense v-if="false">
        <v-select
          label="Day"
          :items="myDays"
          v-model="displayDay"
        ></v-select>
      </v-row>
      <!-- Pool Summary -->
      <v-row dense v-if="pool.matches.length > 0">
        <v-col cols="12" sm="4">
          <v-btn color="color3" text
            @click="hideSum = !hideSum"
            :x-small="tournament.isLeague"
          >
            {{hideSum ? 'Show ' : 'Hide '}}Summary
          </v-btn>
        </v-col>
        <v-col cols="12" sm="8" class="pa-0 ma-0 text-right">
          <manual-finish v-if="view.adminAndRefs && !pool.locked"
            @finish-manually="onFinishManually"
            :pool="pool"
            :teamColors="teamColors"
            :x-small="tournament.isLeague"
          ></manual-finish>
          <pdf-maker
            :pools="round.pools"
            :tournament="tournament"
            :poolId="pool.id"
            btn-class="mr-1"
            v-if="customPrint && view.adminAndRefs"></pdf-maker>
          <v-btn
            color="color3Text color3--text"
            class="mr-1"
            fab
            small
            @click.stop="print"
            v-if="!customPrint && view.adminAndRefs"
          >
            <v-icon small>fas fa-print</v-icon>
          </v-btn>
          <unlock-dialog
            v-if="view.adminAndRefs && pool.locked && !tournament.locked"
            @unlock-click="onLockToggle"
            :inline="true"
            :warn="isInfluencer"
          ></unlock-dialog>
          <player-cards-list-dialog
            ref="cards"
            :playerIds="playerIds"
            v-if="!division.isAdults"
          ></player-cards-list-dialog>
        </v-col>
        <v-col cols="12" v-if="tournament.organizationId === 224">
          <v-alert type="info" :value="true" text>
            Need a team announced? Text details to {{phone}}
          </v-alert>
        </v-col>
        <v-expand-transition>
          <v-col cols="12" v-if="!hideSum">
            <summary-table
              :teams="rankedTeams"
              :advanced="advanced"
              :division="division"
              :teamColorsInverse="teamColorsInverse"
              :tournament="tournament"
              :showSummaries="showSummaries"
              :isMatch="pool.hasMatches"
              :isDuals="pool.isDuals"
              :hideSeeds="hideSeeds"
            ></summary-table>
            <team-message-button
              v-if="view.admin"
              :tournamentId="tournament.id"
              :teams="rankedTeams"
              :xs="true"
            ></team-message-button>
            <match-print
              v-if="tournament.isNcaa && view.adminAndRefs"
              :tournament="tournament"
              :division="division"
              :round="round"
              :matches="pool.matches"
              :pool="pool"
              :block="false"
              :text="true"
              :xSmall="true"
            ></match-print>
          </v-col>
        </v-expand-transition>
      </v-row>
      <!-- Pool Summary Buttons -->
      <v-row dense :justify="showTiebreaks ? 'space-between' : 'end'"  v-if="pool.matches.length > 0">
        <v-btn
          text
          small
          color="color3"
          class="pa-0 ma-0"
          @click.stop="dialog = true"
          v-if="showTiebreaks && !hideSum"
        >tie breaks</v-btn>
        <v-btn
          text
          small
          color="color3"
          class="pa-0 ma-0"
          v-if="$vuetify.breakpoint.smAndUp && !hideSum"
          @click.stop="advanced = !advanced"
        >{{advanced ? 'Simple' : 'Advanced'}}</v-btn>
      </v-row>
      <!-- Match style alert -->
      <v-row dense id="match-style">
        <v-col cols="12">
          <v-alert
            type="info"
            :class="$vuetify.breakpoint.smAndDown ? 'body-2' : 'subtitle-1'"
          >
            All matches are {{pool.matchDescription}}.
          </v-alert>
        </v-col>
      </v-row>
      <v-row dense v-if="tournament.showStartTimes && startTime">
        <v-col cols="12" class="pa-0">
          <v-alert type="info" :value="true" class="black--text ma-0" prominent text>
            <strong>Play starts {{startTime.format('ddd h:mm A')}}</strong>
          </v-alert>
        </v-col>
      </v-row>
      <!-- Record all -->
      <v-row dense justify="end" v-if="view.adminAndRefs && !pool.locked && pool.matches.length > 0">
        <edit-all-dialog
          :pool="pool"
          :round="round"
          :division="division"
        ></edit-all-dialog>
      </v-row>
      <!-- Matches -->
      <v-row dense id="first-match">
        <template v-if="pool.isDuals">
          <v-col cols="12" md="6" v-for="(dual) in poolDuals" :key="`${pool.id}_${dual.n}`">
            <dual-match-up-card
              v-if="!division.isNcaaX"
              :dual="dual"
              :pool="pool"
              :myKey="pool.id"
              :round="round"
              :division="division"
            ></dual-match-up-card>
            <dual-card
              v-else
              :dual="dual"
              :pool="pool"
              :myKey="pool.id"
              :round="round"
              :division="division"
            ></dual-card>
          </v-col>
        </template>
        <template v-else>
          <v-col cols="12" md="6" v-for="(match) in poolMatches" :key="`${pool.id}_${match.number}`">
            <match-card
              :ref="`match${match.number}`"
              :matchIn="match"
              :pool="pool"
              :teamColors="teamColors"
              :round="round"
              :division="division"
              :poolLeague="tournament.isLeague"
            ></match-card>
          </v-col>
        </template>
      </v-row>
      <v-row dense v-if="view.adminAndRefs && !pool.locked && !tournament.locked">
        <add-match :pool="pool" btn-class="xsmall" v-if="!tournament.isLeague"></add-match>
      </v-row>
      <v-row v-if="round.freePlay && view.adminAndRefs">
        <v-col cols="12" class="text-center">
          <!-- Match Up Creator -->
          <match-up-creator
            :time="round.dtStart"
            :roundId="round.id"
          ></match-up-creator>
        </v-col>
      </v-row>
    </v-container>
    <!-- Tie break dialog -->
    <v-dialog
      v-model="dialog"
      max-width="500px"
      v-if="showTiebreaks"
    >
      <v-card>
        <v-toolbar color="color2 color2Text--text">
          <v-toolbar-title>How Ties Were Broken</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-row >
            <v-col class="x12" >
              <v-list>
                <template v-for="(note, i) in tieBreakNotes">
                  <v-subheader v-if="note.header" :key="i">{{note.text}}</v-subheader>
                  <v-list-item v-else :key="i">{{note.text}}</v-list-item>
                </template>
              </v-list>
            </v-col>
          </v-row>
          <v-row dense justify="end">
            <v-col class="xs">
              {{pool.tiebreakers.map(t => `${t.short} = ${t.text}`) | formatArray}}
            </v-col>
            <v-btn
              text
              color="color3"
              @click.stop="dialog = false"
            >close</v-btn>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <v-btn
      v-if="courtFab"
      color="color3 color3Text--text"
      fab
      fixed
      bottom right
      :href="courtFab.url"
      target="_blank"
      :small="$vuetify.breakpoint.xs"
    >
      <v-icon>{{courtFab.icon}}</v-icon>
    </v-btn>

  </v-card>
</template>

<script>
import RouteMixin from '@/Mixins/RouteMixin'
import RoundMixin from '@/Mixins/RoundMixin'
import SummaryTable from '@/components/Standings/SummaryTable.vue'
import MatchCard from '@/components/Tournament/Match/FullMatchCard.vue'
import PlayerCardsListDialog from '@/components/Player/PlayerCardsListDialog'
import firstBy from 'thenby'
import { mapGetters } from 'vuex'
import flatten from '@/helpers/ArrayFlatten'
import moment from 'moment'
const AddMatch = () => import('@/components/Tournament/Pools/AddMatchDialog.vue')
const ManualFinish = () => import('@/components/Tournament/Pools/ManualFinishDialog.vue')
const EditAllDialog = () => import('@/components/Tournament/Pools/EditAllDialog.vue')
const UnlockDialog = () => import('@/components/Tournament/Pools/LockToggle.vue')
const DualCard = () => import('@/components/Tournament/Duals/DualCard.vue')
const DualMatchUpCard = () => import('@/components/Tournament/Duals/DualMatchUpCard.vue')
const PdfMaker = () => import('@/components/Tournament/Pools/PdfMaker.vue')
const TeamMessageButton = () => import('@/components/Notifications/TeamMessageButton')
const MatchUpCreator = () => import('@/components/Tournament/Courts/MatchUpCreatorDialog')
const MatchPrint = () => import('@/components/Printing/MatchPrint')

export default {
  mixins: [RouteMixin, RoundMixin],
  props: ['_poolId', '_dayId'],
  data () {
    return {
      advanced: false,
      dialog: false,
      editAll: false,
      loading: false,
      summaries: false,
      hideSum: false,
      displayDay: null
    }
  },
  computed: {
    ...mapGetters([
      'admin',
      'view',
      'getDivision',
      'teamColors',
      'teamColorsInverse',
      'tournament',
      'getTeam'
    ]),
    poolMatches () {
      return this.displayDay ? this.pool.matches.filter(f => f.day === this.displayDay) : this.pool.matches
    },
    poolDuals () {
      return this.displayDay ? this.pool.duals.filter(f => f.myDays.includes(this.displayDay)) : this.pool.duals
    },
    myDays () {
      return this.pool && [...new Set(this.pool.matches.map(m => m.day))]
    },
    rumble () {
      return this.tournament.jProps && this.tournament.jProps.rumble
    },
    phone () {
      // return '(717) 371-9673'
      if (!this.pool.courts || !this.rumble) return null
      if (this.pool.courts.toLowerCase().startsWith('p')) return this.rumble.pal
      if (this.pool.courts.toLowerCase().startsWith('c')) return this.rumble.cov
      if (this.pool.courts.toLowerCase().startsWith('s')) return this.rumble.sun
      return this.rumble.main
    },
    startTime () {
      const st = this.pool.startTimeCalc || moment(this.round.dtStart)
      const dtStartL = moment(st.format().replace('Z', this.division.location.offset))
      return st && dtStartL.isAfter() ? st : null
    },
    useLogos () {
      return this.division && this.division.useLogos
    },
    customPrint () {
      return this.tournament.props.includes('customPrint') || this.division.props.includes('customPrint')
    },
    isInfluencer () {
      return !!this.tournament.selectors.filter(f => f.fromRoundId === this.roundId).length
    },
    division () {
      return this.getDivision(this.divisionId)
    },
    round () {
      if (!this.division) return null
      return this.division.days.find(f => f.id === (this.dayId || this._dayId))
    },
    flight () {
      return this.round && this.round.flights[0]
    },
    pool () {
      return this.division.pools.find(f => f.id === (this.poolId || this._poolId))
    },
    totalPoints () {
      return this.division.props.includes('total-points')
    },
    rankedTeams () {
      const teams = this.pool.rankedTeams.map(pt => {
        const t = this.getTeam(pt.teamId)
        const s = this.showSummaries && pt.summary
        const seed = this.round.number === 1 ? t.seed : pt.roundSeed || t.seed

        const stats = s || pt
        return {
          slot: pt.slot,
          seed: seed,
          teamId: pt.teamId,
          players: t.players,
          playerNames: this.division.isBVNE ? t.playerNamesCommit : t.playerNames,
          wins: this.pool.isDuals && this.division.dualText.startsWith('D') ? stats.dualWins : stats.matchWins,
          losses: this.pool.isDuals && this.division.dualText.startsWith('D') ? stats.dualLosses : stats.matchLosses,
          matchWins: stats.matchWins,
          matchLosses: stats.matchLosses,
          dualWins: stats.dualWins,
          dualLosses: stats.dualLosses,
          rank: stats.finish || pt.rank,
          manuallyChanged: s && pt.rank !== s.finish,
          name: t.name,
          setWins: stats.setWins,
          setLosses: stats.setLosses,
          pointsFor: stats.pointsFor,
          pointsAgainst: stats.pointsAgainst,
          pointDiff: stats.pointsFor - stats.pointsAgainst,
          pointDiffRatio: stats.matchWins + stats.matchLosses ? stats.pointsFor + stats.pointsAgainst === 0 ? 0 : stats.pointsFor / stats.pointsAgainst : null,
          forfeit: pt.forfeit,
          class: !this.advanced || this.$vuetify.breakpoint.mdAndUp ? 'headline' : null,
          logo: this.useLogos && t.logo,
          countryCode: t.getCountryCode()
        }
      })
      teams.sort(firstBy('rank'))
      return teams
    },
    tieBreakNotes () {
      return this.pool.tiebreakNotes
    },
    xsHeaders () {
      return [
        { align: 'left', sortable: false },
        { text: 'Team', align: 'left', sortable: false, value: 'name' },
        { text: this.totalPoints ? 'Total Points' : 'Record', align: 'center', sortable: false }
      ]
    },
    newHeaders () {
      const m = this.pool.setting.isMatch
      const a = this.advanced
      const d = this.pool.isDuals
      const t = this.totalPoints
      return [
        { text: this.showSummaries ? 'Finish' : 'Standing', align: 'center', sortable: false, value: 'rank', show: true },
        { text: 'Team Name', align: 'left', sortable: false, value: 'name', show: true },
        { text: 'Total Points', align: 'center', sortable: false, value: 'pointsFor', show: !a && t },
        { text: 'Wins', align: 'center', sortable: false, value: 'wins', show: (!a && !t) || (a && !m) },
        { text: 'Losses', align: 'center', sortable: false, value: 'losses', show: (!a && !t) || (a && !m) },
        { text: 'Duals', align: 'center', sortable: false, value: 'duals', show: a && d },
        { text: 'Matches', align: 'center', sortable: false, value: 'matches', show: a && m },
        { text: 'Sets', align: 'center', sortable: false, value: 'sets', show: a && m },
        { text: 'PF', align: 'center', sortable: false, value: 'pointsFor', show: a },
        { text: 'PA', align: 'center', sortable: false, value: 'pointsAgainst', show: a },
        { text: 'PD', align: 'center', sortable: false, value: 'pointDiff', show: a },
        { text: 'PR', align: 'center', sortable: false, value: 'pointDiffRatio', show: a }
      ].filter(f => f.show)
    },
    headers () {
      if (!this.pool) return []
      if (!this.advanced) {
        return [
          { text: this.showSummaries ? 'Finish' : 'Standing', align: 'center', sortable: false },
          { text: 'Team Name', align: 'left', sortable: false },
          { text: 'Wins', align: 'center', sortable: false },
          { text: 'Losses', align: 'center', sortable: false }
        ]
      }

      if (this.pool.setting.isMatch) {
        return [
          { text: this.showSummaries ? 'Finish' : 'Standing', align: 'center', sortable: false },
          { text: 'Team Name', align: 'left', sortable: false },
          { text: 'Matches', align: 'center', sortable: false },
          { text: 'Sets', align: 'center', sortable: false },
          { text: 'PF', align: 'center', sortable: false },
          { text: 'PA', align: 'center', sortable: false },
          { text: 'PD', align: 'center', sortable: false },
          { text: 'PR', align: 'center', sortable: false }
        ]
      }
      return [
        { text: this.showSummaries ? 'Finish' : 'Standing', align: 'center', sortable: false },
        { text: 'Team Name', align: 'left', sortable: false },
        { text: 'Wins', align: 'center', sortable: false },
        { text: 'Losses', align: 'center', sortable: false },
        { text: 'PF', align: 'center', sortable: false },
        { text: 'PA', align: 'center', sortable: false },
        { text: 'PD', align: 'center', sortable: false },
        { text: 'PR', align: 'center', sortable: false }
      ]
    },
    lockDTO () {
      return this.pool.teams.map(team => {
        return {
          id: team.id,
          summary: {
            finish: team.rank,
            dualWins: team.dualWins,
            dualLosses: team.dualLosses,
            matchWins: team.matchWins,
            matchLosses: team.matchLosses,
            setWins: team.setWins,
            setLosses: team.setLosses,
            pointsFor: team.pointsFor,
            pointsAgainst: team.pointsAgainst
          }
        }
      })
    },
    showSummaries () {
      return this.pool.locked || this.summaries
    },
    showTiebreaks () {
      return this.tieBreakNotes && this.tieBreakNotes.length > 0 && !this.manuallyChanged
    },
    manuallyChanged () {
      return !!this.rankedTeams.find(team => team.manuallyChanged)
    },
    courtFab () {
      const courts = this.pool.courts ? this.pool.courts.split(' ') : []
      return courts.length && this.tournament.fabs.find(f => f.type === 'court' && f.courtName === courts[0])
    },
    playerIds () {
      return flatten(this.pool.teams.map(pt => {
        const t = this.getTeam(pt.teamId)
        return t.players.map(p => p.playerProfileId)
      }))
    }
  },
  methods: {
    teamClick (id) {
      this.$router.push({ name: 'division-team', params: { tournamentId: this.tournament.id, teamId: id } })
    },
    getRep (teamId) {
      var x = this.getTeam(teamId)
      return x.getRep(this.division.repField)
    },
    onFinishManually (finishes) {
      this.lockToggle(finishes)
    },
    onLockToggle () {
      this.lockToggle()
    },
    lockToggle (manualFinishes) {
      this.loading = true
      const dto = {
        locked: !this.pool.locked,
        teams: this.lockDTO
      }
      if (manualFinishes) {
        manualFinishes.forEach(finish => {
          const team = dto.teams.find(f => f.id === finish.id)
          team.summary.finish = +finish.finish
          team.summary.matchWins = +finish.matchWins
          team.summary.matchLosses = +finish.matchLosses
          team.summary.pointsFor = +finish.pointsFor
          team.summary.pointsAgainst = +finish.pointsAgainst
        })
      }
      this.pool.patch(dto)
        .then(() => {
          this.pool.locked = dto.locked
          dto.teams.forEach(t => {
            const team = this.pool.teams.find(f => f.id === t.id)
            team.summary = t.summary
          })
          this.loading = false
        })
    },
    print () {
      window.print()
    },
    checkScroll () {
      if (this.$route.query.number) {
        const target = this.$refs[`match${this.$route.query.number}`]
        if (target) {
          const t = target[0]
          this.$vuetify.goTo(t, {
            duration: 333,
            offset: 333,
            easing: 'easeInOutCubic'
          })
          t.$el.scrollIntoView()
        }
      }
    }
  },
  watch: {
    'pool.matches': {
      handler: function () {
        this.pool && this.pool.summarize()
      },
      deep: true
    }
  },
  components: {
    MatchCard,
    EditAllDialog,
    UnlockDialog,
    ManualFinish,
    AddMatch,
    PdfMaker,
    DualCard,
    DualMatchUpCard,
    PlayerCardsListDialog,
    SummaryTable,
    TeamMessageButton,
    MatchUpCreator,
    MatchPrint
  },
  mounted () {
    if (['Matches', 'Friday', 'Saturday', 'Sunday'].includes(this.round.name) || this.tournament.isLeague || this.round.freePlay) {
      this.hideSum = true
    }
    this.$nextTick(this.checkScroll())
    // console.log(this.myDays)
    // if (this.myDays) this.displayDay = this.myDays[0]
  }
}
</script>

<style scoped>
.sm {
  font-size: smaller !important;
}
.xs {
  font-size: x-small;
}
.forfeit {
  text-decoration: line-through;
}
.v-avatar span {
  font-size: 13px;
}
.nested td {
  border: none !important;
}
.nested td > div {
  line-height: initial;
}
</style>
